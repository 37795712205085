<script>
// Mobile vs Desktop not really needed here yet
import OrderListItem from "./OrderListItem.vue";
// Mobile vs Desktop not really needed here yet
import {
  STORE_WITH_SHOPIFY,
  ORDER_CREATED,
  ORDER_UPDATED,
  ORDERS,
} from "./OrdersGQL";
import get from "lodash/get";
import groupBy from "lodash/groupBy";
import { bizDay } from "@/utils/datetime";

import { isBefore, parseISO } from "date-fns";

import {
  SORT_ORDER,
  VxDataList,
  VxDataListGroup,
  PaginationOptionsUtils,
  ServerPaginationUtils,
} from "@/core-ui";

export default {
  name: "OrderListView",
  components: {
    VxDataList,
    OrderListItem,
    VxDataListGroup,
  },
  props: {
    parsedFilter: {
      type: Object,
      default: undefined,
    },
    storeId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    const defaultPaginationOptions =
      PaginationOptionsUtils.buildDefaultPaginationOptions();
    const defaultSortOption = PaginationOptionsUtils.buildDefaultSortOptions({
      sortBy: "createdAt",
      sortOrder: SORT_ORDER.ASC,
    });

    return {
      ordersLoading: 0,
      orders: [],

      paginationOptions: defaultPaginationOptions,
      sortOptions: defaultSortOption,
      serverPaginationRequest:
        PaginationOptionsUtils.buildDefaultServerPaginationRequest(
          defaultPaginationOptions,
          defaultSortOption
        ),

      statusToggle: "all",
      loadingList: [],
      starsOnly: false,
      columnCssClasses: [
        "star-cell",
        "product-cell",
        "customer-cell",
        "sorter-cell",
        "status-cell",
        "time-cell",
        "price-cell",
      ],
    };
  },
  computed: {
    isSmallScreen() {
      // TODO - put this in some common lib
      return this.$vuetify.breakpoint.smAndDown;
    },
    queryVariables() {
      return {
        storeId: this.storeId,
        ...this.serverPaginationRequest,
        filter: this.parsedFilter.GQLdata,
        statusFilter:
          this.statusToggle === "all" ? undefined : this.statusToggle,
      };
    },
    storeHasSharedSecret() {
      return get(this, "store.integrationShopify.hasSharedSecret");
    },
    groupedOrders() {
      const grouped = groupBy(
        this.orders.map((order) => {
          return {
            ...order,
            orderDate: bizDay(order.createdAt),
          };
        }),
        (mappedOrder) => mappedOrder.orderDate
      );
      return grouped;
    },
  },
  watch: {
    storeHasSharedSecret(v) {
      if (!v) {
        this.$router.push({
          name: "orderSharedSecret",
          params: { storeId: this.$route.params.storeId },
        });
      }
    },
    // When status-toggling, add the status to the `loadingList`
    statusToggle(v, o) {
      if (v !== o) {
        this.loadingList.push(`toggle-${v}`);
      }
    },
  },
  mounted() {},
  methods: {
    onShowMore() {
      if (this.isSmallScreen) {
        // When loading data from server, mobile is handled via load more
        let mobileServerPaginationRequest = {};
        ServerPaginationUtils.updatePage(
          this.paginationOptions,
          this.sortOptions,
          mobileServerPaginationRequest,
          this.paginationOptions.page + 1
        );
        ServerPaginationUtils.fetchMoreData(
          this.$apollo.queries,
          "orders",
          "shopifyOrders",
          {
            ...this.queryVariables,
            ...mobileServerPaginationRequest,
          }
        );
      }
    },
    onPageUpdate(page) {
      ServerPaginationUtils.updatePage(
        this.paginationOptions,
        this.sortOptions,
        this.serverPaginationRequest,
        page
      );
    },
    onItemsPerPageUpdate(itemsPerPage) {
      ServerPaginationUtils.updateItemsPerPage(
        this.paginationOptions,
        this.sortOptions,
        this.serverPaginationRequest,
        itemsPerPage
      );
    },
    selectOrder(order) {
      this.$router.push({
        name: "order_show",
        params: { orderId: order.id },
        preserveQuery: true,
      });
    },
    handleOrderCreated(prev, curr) {
      if (!curr) return prev;

      if (prev.orders.find((order) => order.id === curr.id)) {
        return prev;
      }

      prev.orders.push(curr);

      return prev;
    },
    handleOrderUpdated(prev, curr) {
      if (!curr) return prev;

      let existingIndex = prev.orders.findIndex(
        (order) => order.id === curr.id
      );

      if (existingIndex < 0) {
        return prev;
      }

      const payloadPrecedesCache = (
        { updatedAt: payload },
        { updatedAt: cache }
      ) => {
        const valueToDate = (time) =>
          (typeof time === "string" && parseISO(time)) || time;
        return isBefore(valueToDate(payload), valueToDate(cache));
      };

      const existingOrder = prev.orders[existingIndex];
      if (payloadPrecedesCache(curr, existingOrder)) return prev;

      prev.orders.splice(existingIndex, 1, {
        ...existingOrder,
        ...curr,
      });

      return prev;
    },
  },
  apollo: {
    store: {
      query: STORE_WITH_SHOPIFY,
      fetchPolicy: "cache-and-network",
      variables() {
        return {
          id: this.storeId,
        };
      },
      skip() {
        return !this.storeId;
      },
    },
    orders: {
      query: ORDERS,
      subscribeToMore: [
        {
          document: ORDER_CREATED,
          updateQuery(prev, current) {
            return this.handleOrderCreated(
              prev.orders,
              current.subscriptionData.data.shopifyOrderCreated
            );
          },
        },
        {
          document: ORDER_UPDATED,
          updateQuery(prev, current) {
            return this.handleOrderUpdated(
              prev.orders,
              current.subscriptionData.data.shopifyOrderCreated
            );
          },
        },
      ],
      fetchPolicy: "cache-and-network",
      loadingKey: "ordersLoading",
      variables() {
        return this.queryVariables;
      },
      skip() {
        return !this.storeId;
      },
      update({ shopifyOrders = { edges: [] } }) {
        // Update the loading list and remove any toggle-<status> values
        this.loadingList = this.loadingList.filter(
          (e) =>
            !["toggle-all", "toggle-unfulfilled", "toggle-starred"].includes(e)
        );

        ServerPaginationUtils.updateOptionsFromServerData(
          this.paginationOptions,
          shopifyOrders
        );

        return shopifyOrders.edges.map(({ node }) => node);
      },
    },
  },
};
</script>

<template>
  <VxDataList
    class="order-list"
    :pagination-options="paginationOptions"
    :loading="ordersLoading"
    :empty-data-text="'No orders.'"
    :data-loading-text="'Loading orders.'"
    @update:page="onPageUpdate"
    @update:itemsPerPage="onItemsPerPageUpdate"
    @showMore="onShowMore"
  >
    <template #headerExtension>
      <v-btn-toggle
        mandatory
        color="accent"
        text
        dense
        group
        @change="statusToggle = $event"
      >
        <v-btn
          :value="'all'"
          :loading="loadingList.includes(`toggle-all`)"
          v-text="'all'"
        />
        <v-btn
          :value="'unfulfilled'"
          :loading="loadingList.includes(`toggle-unfulfilled`)"
          v-text="'unfulfilled'"
        />
        <v-btn
          :value="'starred'"
          :loading="loadingList.includes(`toggle-starred`)"
          v-text="'starred'"
        />
      </v-btn-toggle>
    </template>
    <template #groups>
      <VxDataListGroup
        v-for="(orderList, orderDate) in groupedOrders"
        :key="orderDate"
        :column-css-classes="columnCssClasses"
        :group-title="orderDate"
      >
        <OrderListItem
          v-for="item in orderList"
          :key="item.id + '-' + orderDate"
          :item="item"
          @show="selectOrder"
        />
      </VxDataListGroup>
    </template>
  </VxDataList>
</template>

<style lang="scss">
// Use for accessing breakpoint variables
@import "~vuetify/src/styles/styles.sass";

.order-list {
  thead {
    tr {
      td,
      th {
        border-bottom: none !important;
      }
    }
  }

  .star-cell {
    width: 70px;
  }

  .product-cell {
    /* THIS one has auto width */
    width: 100px;
  }

  .sorter-cell {
    width: 170px;
  }

  .status-cell {
    width: 100px;
  }

  .time-cell {
    width: 150px;
  }

  .price-cell {
    width: 150px;
  }

  @media only screen and (max-width: 1500px) {
    .customer-cell {
      width: 250px;
    }

    .status-cell {
      width: 100px;
    }
  }

  @media only screen and (max-width: 1100px) {
    .customer-cell {
      width: 150px;
    }
  }
}

.theme--light .rai-order-list-item--closed {
  background-color: var(--v-ui-lighten5);
}

.theme--dark .rai-order-list-item--closed {
  background-color: var(--v-ui-darken3);
}

.rai-order-list-item--error {
  &.rai-orlid--error {
    td:first-of-type {
      border-left: 6px solid var(--v-error-base);
      padding-left: 6px;
    }
  }
  &.rai-order-list-item--mobile {
    border-left: 6px solid var(--v-error-base);
    padding-left: 10px;
  }
}
</style>
