// This function returns a functional component object,
// which follows our most commonly used pattern of
// mobile vs desktop rendering.
//
// Usage:
// import mobile from "./ExampleComponentMobile.vue"
// import desktop from "./ExampleComponentDesktop.vue"
//
// export default responsiveComponent({ mobile: mobile, desktop: desktop })
//
// - or -, more simply
//
// export default responsiveComponent({ mobile, desktop })
//
// responsiveComponent: Object{mobile, desktop} => FunctionalVueComponentObject
export const responsiveComponent = ({ mobile, desktop, name }) => ({
  name,
  functional: true,
  inheritAttrs: false,
  render(h, context) {
    const responsiveComponent = context.parent.$vuetify.breakpoint.smAndDown
      ? mobile
      : desktop;

    return h(
      responsiveComponent,
      {
        props: context.props,
        ...context.data,
        scopedSlots: context.scopedSlots,
      },
      context.$slots.default
    );
  },
});
