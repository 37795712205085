<script>
import { VxDataListItem } from "@/core-ui";
import PickerSelector from "@/components/orders/PickerSelector";
import EmployeeAvatarQuery from "@/components/employees/EmployeeAvatarQuery";
import { get, compact, upperFirst, lowerCase } from "lodash";
import { materialAbsoluteDateTimeString, materialTime } from "@/utils/datetime";
import { hasSnackbarAccess } from "@/mixins/ui";
import { hasChildDialog } from "@/mixins/routerParams";
import { UPDATE_ORDER_STARRED } from "./OrdersGQL.js";
import { isSameDay, parseISO } from "date-fns";

export default {
  name: "OrderListItem",
  components: {
    VxDataListItem,
    PickerSelector,
    EmployeeAvatarQuery,
  },
  mixins: [hasSnackbarAccess, hasChildDialog],
  inheritAttrs: false,
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    date() {
      if (!this.item) return "";
      if (!this.item.createdAt) return "";

      // return format(this.item.createdAt, "M/D/YYYY [at] h:mm a");
      return upperFirst(
        materialAbsoluteDateTimeString(this.item.createdAt, true)
      );
    },
    timeStr() {
      if (!this.item) return "";
      if (!this.item.createdAt) return "";

      // return format(this.item.createdAt, "h:mm a");
      return materialTime(this.item.createdAt);
    },
    shippingCodeChipColor() {
      const isDark = this.$vuetify.theme.dark;
      const pickup = /pickup|curb|pick.up/i;
      const delivery = /deliver/i;

      if (pickup.test(this.shippingCode))
        return isDark ? "purple" : "purple lighten-4";

      if (delivery.test(this.shippingCode))
        return isDark ? "deep-orange" : "deep-orange lighten-4";

      return isDark ? "light-blue" : "light-blue lighten-3";
    },
    // `statusProps` is Deprecated, after removing chips from status
    statusProps() {
      const props = {};
      if (!this.item) return props;

      if (!!this.item.canceledAt) {
        return Object.assign(props, {
          color: "error",
          text: "cancelled",
        });
      }
      if (this.item.fulfillmentStatus === "fulfilled") {
        return Object.assign(props, {
          color: "success",
          text: this.item.fulfillmentStatus,
        });
      }
      if (this.item.financialStatus === "paid") {
        return Object.assign(props, {
          color: "amber",
          text: this.item.financialStatus,
        });
      }
      if (
        ["refunded", "voided", "pending"].includes(this.item.financialStatus)
      ) {
        return Object.assign(props, {
          color: "error",
          text: this.item.financialStatus,
        });
      }
      return props;
    },
    formattedTotalPrice() {
      if (!this.item.totalPrice) return "";

      return `$${this.item.totalPrice.toFixed(2)}`;
    },
    customerName() {
      return get(this, "item.customer.fullName");
    },
    shippingCityAndProvince() {
      if (!get(this, "item.shippingAddress")) return "";

      let city = get(this, "item.shippingAddress.city");
      if (city) {
        city = city.split(" ").map(lowerCase).map(upperFirst).join(" ");
      }
      return [
        city,
        get(this, "item.shippingAddress.province_code").toUpperCase(),
      ].join(", ");
    },
    shippingCode() {
      if (!this.item) return "";
      if (!this.item.shippingLines) return "";
      if (!this.item.shippingLines.length) return "";

      return this.item.shippingLines[0]["code"];
    },
    shippingTitle() {
      if (!this.item) return "";
      if (!this.item.shippingLines) return "";
      if (!this.item.shippingLines.length) return "";

      return this.item.shippingLines[0]["title"];
    },
    isWarning() {
      if (isSameDay(new Date(), parseISO(get(this, "item.createdAt"))))
        return false;
      if (get(this, "item.financialStatus") !== "paid") return false;
      if ([null, undefined].includes(get(this, "item.fulfillmentStatus")))
        return true;

      return false;
    },
    itemsString() {
      const items = this.item.lineItems.length;
      return `${items} item${items === 1 ? "" : "s"}`;
    },
    computedStatus() {
      if (this.item.financialStatus !== "paid")
        return this.item.financialStatus;
      if (this.item.fulfillmentStatus === "fulfilled") return "fulfilled";
      if (!this.item.pickedAt) return "open";
      if (this.item.pickedAt) return "picking";

      return this.item.fulfillmentStatus || this.item.financialStatus;
    },
    pickedEmployeeId() {
      return (
        get(this, "item.pickedByEmployeeId") ||
        get(this, "item.pickedEmployee.id")
      );
    },
    listItemClasses() {
      return {
        "rai-orlid": true,
        "rai-order-list-item--mobile": true,
        "rai-orlid--closed": !!this.item.closedAt,
        "rai-orlid--unread": !this.item.closedAt,
        "rai-orlid--error": this.isWarning,
        "rai-order-list-item--error": this.isWarning,
        "rai-order-list-item--closed": !!this.item.closedAt,
      };
    },
    lineItemsText() {
      return [
        this.item.lineItems.length,
        `item${this.item.lineItems.length === 1 ? "" : "s"}`,
      ].join(" ");
    },
    itemsAndShippingCode() {
      const shippingCode = get(this, "item.shippingLines", [])[0].code;

      return compact([this.itemsString, shippingCode]).join(" - ");
    },
    itemsAndLocation() {
      const items = this.item.lineItems.length;
      const location = this.shippingCityAndProvince;

      return compact([items, location]).join(" - ");
    },
    itemsAndTotal() {
      const items = this.item.lineItems.length;
      return compact([items, this.formattedTotalPrice]).join(" - ");
    },
  },
  methods: {
    goToOrder(orderId) {
      this.$router.push({ name: "orderDetail", params: { orderId: orderId } });
    },
    showOrder() {
      this.$emit("show", this.item);
    },
    async handleStarClick() {
      const orderId = this.item.id;
      this.loading = true;
      try {
        const {
          data: {
            updateOrderStarred: { errors },
          },
        } = await this.$apollo.mutate({
          mutation: UPDATE_ORDER_STARRED,
          variables: { id: orderId },
        });

        if (errors && errors.length) {
          throw errors.join(". ");
        }
        // this.showSnackbar({
        //   text: `Order has been updated`
        // });
      } catch (error) {
        this.showSnackbar({
          text: `An error occurred while starring order. ${error}`,
        });
      }
      this.loading = false;
    },
  },
};
</script>

<template>
  <VxDataListItem
    :class="listItemClasses"
    v-bind="{ ...$attrs, threeLine: true }"
    v-on="$listeners"
    @click.stop="showOrder"
  >
    <template #desktop>
      <td class="tdicon text--secondary">
        <v-btn icon :loading="loading" @click.stop="handleStarClick">
          <v-icon dense :color="item.isStarred ? 'amber lighten-2' : null">
            {{ item.isStarred ? "$starOn2" : "$starOff2" }}
          </v-icon>
        </v-btn>
      </td>
      <td class="text-right rai-data w-ch-10 pl-0">
        <span class="font-weight-medium">{{ item.name || item.number }}</span>
      </td>
      <td class="text-left rai-data">
        <span class="font-weight-bold">{{ customerName }}</span>
        <span class="text--secondary pl-4">{{ itemsString }}</span>
        <span v-if="shippingCode" class="text--secondary pl-4">
          <v-chip
            x-small
            :color="shippingCodeChipColor"
            class="text-uppercase"
            v-text="shippingCode"
          />
        </span>
      </td>
      <td>
        <PickerSelector :shopify-order-id="item.id" />
      </td>
      <td
        class="text-left rai-data text--secondary text-caption text-uppercase"
      >
        {{ statusProps.text }}
      </td>

      <td class="rai-data text-right">
        <span class="pr-2">{{ timeStr }}</span>
      </td>
      <td class="rai-data text-right">{{ formattedTotalPrice }}</td>
    </template>

    <template #mobile>
      <v-list-item-icon class="mr-4">
        <v-icon size="28" :color="item.isStarred ? 'amber lighten-2' : 'grey'">
          {{ item.isStarred ? "$starOn2" : "$starOff2" }}
        </v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-subtitle>
          <span class="font-weight-bold rai-data text--secondary">
            {{ item.name }}
          </span>
        </v-list-item-subtitle>
        <v-list-item-title class="font-weight-bold" v-text="customerName" />
        <v-list-item-subtitle>
          <v-chip x-small :color="shippingCodeChipColor">
            <span class="text-uppercase text-truncate d-block">{{
              shippingCode
            }}</span>
          </v-chip>
        </v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-action class="mt-8">
        <v-list-item-action-text>
          <span>{{ itemsString }}</span>
          <span class="ml-2">{{ formattedTotalPrice }}</span>
        </v-list-item-action-text>

        <!-- If currently being picked, show the employee avatar -->
        <v-list-item-action-text
          v-if="computedStatus === `picking`"
          class="mt-1"
        >
          <span class="mr-1">Picking</span>
          <EmployeeAvatarQuery :employee-id="pickedEmployeeId" size="28" />
        </v-list-item-action-text>
        <!-- Otherwise, just show the status -->
        <v-list-item-action-text
          v-else
          class="text-overline mt-1"
          v-text="computedStatus"
        />
      </v-list-item-action>
    </template>
  </VxDataListItem>
</template>

<style lang="scss">
tr.rai-orlid {
  td {
    height: 40px;
    padding-left: 0;
  }
  td.tdicon {
    width: 40px;
    padding-left: 12px;
    padding-right: 6px;
    padding-top: 0;
    padding-bottom: 0;
    svg {
      margin: auto;
    }
  }
  .w-ch-8 {
    width: 8ch;
  }
  .w-ch-9 {
    width: 9ch;
  }
  .w-ch-10 {
    width: 10ch;
  }
}
.mw-ch-6 {
  min-width: 6ch;
}
</style>
